// Generated by Framer (033a048)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["FdYXDFILH", "YdM5TLQSe"];

const serializationHash = "framer-PBt2Q"

const variantClassNames = {FdYXDFILH: "framer-v-14pq3pb", YdM5TLQSe: "framer-v-1adpt8v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "FdYXDFILH", "Variant 2": "YdM5TLQSe"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FdYXDFILH"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FdYXDFILH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-14pq3pb", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"FdYXDFILH"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({YdM5TLQSe: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS1yZWd1bGFy", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif"}}>Nous sommes </motion.p></React.Fragment>} className={"framer-1rbyaz1"} fonts={["GF;Manrope-regular"]} layoutDependency={layoutDependency} layoutId={"jA8WywhuN"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PBt2Q.framer-13rathe, .framer-PBt2Q .framer-13rathe { display: block; }", ".framer-PBt2Q.framer-14pq3pb { height: 19px; overflow: hidden; position: relative; width: 99px; }", ".framer-PBt2Q .framer-1rbyaz1 { flex: none; height: auto; left: 49%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 99
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YdM5TLQSe":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerit6ktqVC_: React.ComponentType<Props> = withCSS(Component, css, "framer-PBt2Q") as typeof Component;
export default Framerit6ktqVC_;

Framerit6ktqVC_.displayName = "Nous sommes";

Framerit6ktqVC_.defaultProps = {height: 19, width: 99};

addPropertyControls(Framerit6ktqVC_, {variant: {options: ["FdYXDFILH", "YdM5TLQSe"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerit6ktqVC_, [{explicitInter: true, fonts: [{family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_A87jxeN7B.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})